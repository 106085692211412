import { CartKey } from '@custom_hooks/use_cart';
import { ItemDetailsBaseKey } from '@custom_hooks/use_item_details';
import { Item } from '@interfaces/item.interface';
import { queryClient } from '@pages/_app';
import { pathNameToPageName } from '@utility_functions/getPageName';
import { CartDetail, mockCartDetail } from '../interfaces/cart.interface';
import { MerchandisingModel } from '../interfaces/merchandising_model.type';
import { HttpService } from './http_service';
import { SegmentService } from './segment_service';

function getCart(): Promise<CartDetail> {
  if (process.env.NEXT_PUBLIC_DEV_MOCK_API_CALLS === 'true') {
    return Promise.resolve(mockCartDetail({}));
  }
  return HttpService.get(`/api/v1/public/current_cart`);
}

function updateCartDuration(duration: number) {
  return HttpService.put(`/api/v1/public/current_cart`, {
    duration: duration,
  }).then((cartDetails: CartDetail) => {
    cartDetails = { ...cartDetails, subscriptionDuration: duration };
    queryClient.setQueryData(CartKey, cartDetails);
    return cartDetails;
  });
}

function sendOpenCartEvent(cart: CartDetail): CartDetail {
  let event = new CustomEvent('openCart', {});
  document.dispatchEvent(event);
  return cart;
}

interface AddToCartPayload {
  items: AddToCartLineItemParams[];
}

interface AddToCartLineItemParams {
  quantity: number;
  itemId: number;
  merchandisingModel?: MerchandisingModel;
}

function addItemToCart({
  quantity,
  item,
  merchandisingModel,
  segmentContext,
}: {
  quantity: number;
  item: Item;
  merchandisingModel?: MerchandisingModel;
  segmentContext: string;
}): Promise<CartDetail> {
  trackProductAdded(item, merchandisingModel, segmentContext);

  const itemPayload: AddToCartLineItemParams = {
    itemId: item.id,
    quantity,
  };

  if (merchandisingModel) {
    itemPayload.merchandisingModel = merchandisingModel;
  }

  const payload: AddToCartPayload = {
    items: [itemPayload],
  };
  return HttpService.post(`/api/v1/public/current_cart/line_items`, payload)
    .then(invalidateCart)
    .then(sendOpenCartEvent);
}

function trackProductAdded(
  item: Item,
  merchandisingModel: MerchandisingModel | undefined,
  context: string
) {
  SegmentService.trackEcommerceEvent('Product Added', {
    product_id: item.id,
    name: item.name,
    price: item.retailPrice,
    quantity: 1,
    image_url: item.primaryImageListUrl,
    item_merchandising_model: item.merchandisingModel,
    merchandising_model: merchandisingModel,
    page_name: pathNameToPageName(location?.pathname),
    context,
  });
}

function removeItem(
  lineItemId: number,
  useCase: 'all' | 'unavailable'
): Promise<CartDetail> {
  return HttpService.destroy(
    `/api/v1/public/current_cart/line_items/${lineItemId}?use_case=${useCase}`
  ).then(invalidateCart);
}

function removeAllUnavailable(): Promise<CartDetail> {
  return HttpService.destroy(
    '/api/v1/public/current_cart/remove_unavailable'
  ).then(invalidateCart);
}

function changeLineItemQuantity(
  lineItemId: number,
  quantity: number
): Promise<CartDetail> {
  return HttpService.put(
    `/api/v1/public/current_cart/line_items/${lineItemId}/update_quantity`,
    {
      quantity,
    }
  ).then(invalidateCart);
}

function invalidateCart(cart: CartDetail): CartDetail {
  queryClient.invalidateQueries(CartKey);
  queryClient.invalidateQueries(ItemDetailsBaseKey);

  return cart;
}

function checkout() {
  return HttpService.post('/checkout', {});
}

export type CheckoutErrors = {
  error: { action: 'create_account'; success: false };
};

export const CartService = {
  getCart,
  updateCartDuration,
  addItemToCart,
  removeItem,
  removeAllUnavailable,
  changeLineItemQuantity,
  checkout,
};
