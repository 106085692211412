import { Item } from '@interfaces/item.interface';
import { makeMock } from '@utility_functions/makeMock';
import { SortFieldOptions } from './sort_field_options';

export interface FilteredItemResponse {
  query_params: QueryParams;
  items: (Item & {
    itemTypeIds?: number[];
  })[];
  filter_counts: FilterCounts;
}

/**
 * matches ruby/app/serializers/api/v2/search/count_serializer.rb
 */
export interface FilterCounts {
  room_types: FilterCount[];
  item_types: FilterCount[];
  color_families: FilterCount[];
  bed_sizes: FilterCount[];
  rug_sizes: FilterCount[];
  materials: FilterCount[];
}

export interface FilterCount {
  id: number;
  name: string;
  qty: number;
  slug?: string; // todo -remove
}

export interface QueryParams {
  found_count: number;
  records_per_page: number;
  page_count: number;
  page: number;
  params: Params;
  cart_id: number | null;
}

export interface Params {
  room_types?: string;
  item_types?: string;
  color_families?: string;
  per_page?: string;
  sort_field?: SortFieldOptions;
  materials?: string;
}

export const mockFilteredItemResponse = makeMock<FilteredItemResponse>({
  query_params: {
    found_count: 683,
    records_per_page: 42,
    page_count: 1,
    page: 1,
    params: {
      per_page: '42',
    },
    cart_id: 3009779,
  },
  items: [
    {
      id: 1349,
      version: 15,
      slug: 'accent-chairs-asher-accent-chair-navy',
      name: 'Asher Accent Chair, Navy',
      primaryImageAltText: '1349_Asher-Accent-Chair-Navy_Front_2021',
      primaryImageListUrl:
        '//fernish.com/images/f17980813df2c7ef0d36f50d2c8b479b/medium/1349_Asher-Accent-Chair-Navy_Front_2021.jpg?1626287805',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 799.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 3,
        inStock: 3,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 78,
        },
        {
          duration: 5,
          rate: 65,
        },
        {
          duration: 6,
          rate: 56,
        },
        {
          duration: 7,
          rate: 50,
        },
        {
          duration: 8,
          rate: 45,
        },
        {
          duration: 9,
          rate: 41,
        },
        {
          duration: 10,
          rate: 38,
        },
        {
          duration: 11,
          rate: 36,
        },
        {
          duration: 12,
          rate: 34,
        },
        {
          duration: 12,
          rate: 34,
        },
        {
          duration: 18,
          rate: 34,
        },
        {
          duration: 24,
          rate: 34,
        },
      ],
      itemTypeIds: [2],
      availability: 'available',
    },
    {
      id: 1039,
      version: 30,
      slug: 'consoles-basilone-console-table-black-with-clear-glass',
      name: 'Basilone Console Table, Black with Clear Glass',
      primaryImageAltText:
        '1039_Basilone-Console-Table-BlackwithClearGlass_Front',
      primaryImageListUrl:
        '//fernish.com/images/a7824b06a28f94a51ec91b92e35fdf03/medium/1039_Basilone-Console-Table-BlackwithClearGlass_Front.jpg?1599606933',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 399.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 2,
        inStock: 2,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 39,
        },
        {
          duration: 5,
          rate: 33,
        },
        {
          duration: 6,
          rate: 28,
        },
        {
          duration: 7,
          rate: 25,
        },
        {
          duration: 8,
          rate: 23,
        },
        {
          duration: 9,
          rate: 21,
        },
        {
          duration: 10,
          rate: 19,
        },
        {
          duration: 11,
          rate: 18,
        },
        {
          duration: 12,
          rate: 17,
        },
        {
          duration: 18,
          rate: 17,
        },
        {
          duration: 24,
          rate: 17,
        },
      ],
      itemTypeIds: [27],
      availability: 'available',
    },
    {
      id: 1230,
      version: 14,
      slug: 'outdoor-breeze-outdoor-patio-chaise',
      name: 'Breeze Outdoor Patio Chaise',
      primaryImageAltText: '1230_Breeze-Outdoor-Patio-Chaise_3Q_2021',
      primaryImageListUrl:
        '//fernish.com/images/c2327a32ccc483f6e4103bf6ca5123fc/medium/1230_Breeze-Outdoor-Patio-Chaise_3Q_2021.jpg?1618947893',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 599.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 3,
        inStock: 3,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 59,
        },
        {
          duration: 5,
          rate: 49,
        },
        {
          duration: 6,
          rate: 42,
        },
        {
          duration: 7,
          rate: 37,
        },
        {
          duration: 8,
          rate: 34,
        },
        {
          duration: 9,
          rate: 31,
        },
        {
          duration: 10,
          rate: 29,
        },
        {
          duration: 11,
          rate: 27,
        },
        {
          duration: 12,
          rate: 26,
        },
        {
          duration: 18,
          rate: 26,
        },
        {
          duration: 24,
          rate: 26,
        },
      ],
      itemTypeIds: [22],
      availability: 'available',
    },
    {
      id: 1612,
      version: 12,
      slug: 'sofas-sectionals-brooks-sectional-reversible-narrow-yellow',
      name: 'Brooks Sectional, Reversible, Narrow, Yellow',
      primaryImageAltText:
        '1612_Brooks-Sectional-Convertible-Narrow-Yellow_Front-Left_2022',
      primaryImageListUrl:
        '//fernish.com/images/8b3fd4043740849da98e0d8cab311011/medium/1612_Brooks-Sectional-Convertible-Narrow-Yellow_Front-Left_2022.jpg?1643765236',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 2499.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 2,
        inStock: 2,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 243,
        },
        {
          duration: 5,
          rate: 202,
        },
        {
          duration: 6,
          rate: 174,
        },
        {
          duration: 7,
          rate: 155,
        },
        {
          duration: 8,
          rate: 139,
        },
        {
          duration: 9,
          rate: 128,
        },
        {
          duration: 10,
          rate: 119,
        },
        {
          duration: 11,
          rate: 112,
        },
        {
          duration: 12,
          rate: 105,
        },
        {
          duration: 18,
          rate: 105,
        },
        {
          duration: 24,
          rate: 105,
        },
      ],
      itemTypeIds: [76],
      availability: 'available',
    },
    {
      id: 1606,
      version: 11,
      slug: 'sofas-sectionals-brooks-sofa-wide-arm-yellow',
      name: 'Brooks Sofa, Wide Arm, Yellow',
      primaryImageAltText: '1606_Brooks Sofa-Wide Arm-Yellow_Front_2022',
      primaryImageListUrl:
        '//fernish.com/images/c4c4fb363913aa2ed07474a5d81906e1/medium/1606_Brooks-Sofa-Yellow-Wide-Arms_front_2022.png?1643744960',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 1799.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 3,
        inStock: 3,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 175,
        },
        {
          duration: 5,
          rate: 146,
        },
        {
          duration: 6,
          rate: 125,
        },
        {
          duration: 7,
          rate: 112,
        },
        {
          duration: 8,
          rate: 101,
        },
        {
          duration: 9,
          rate: 93,
        },
        {
          duration: 10,
          rate: 86,
        },
        {
          duration: 11,
          rate: 81,
        },
        {
          duration: 12,
          rate: 76,
        },
        {
          duration: 18,
          rate: 76,
        },
        {
          duration: 24,
          rate: 76,
        },
      ],
      itemTypeIds: [76],
      availability: 'available',
    },
    {
      id: 847,
      version: 40,
      slug: 'accessories-camden-ivory-ceramic-vase',
      name: 'Camden Ivory Ceramic Vase',
      primaryImageAltText: '847_CamdenVase_Front-Setting',
      primaryImageListUrl:
        '//fernish.com/images/9b0bf1f1298fe222250b45301bb2b03a/medium/847_CamdenVase_Front-Setting.jpg?1585202322',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 30.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 7,
        inStock: 7,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 3,
        },
        {
          duration: 5,
          rate: 3,
        },
        {
          duration: 6,
          rate: 3,
        },
        {
          duration: 7,
          rate: 2,
        },
        {
          duration: 8,
          rate: 2,
        },
        {
          duration: 9,
          rate: 2,
        },
        {
          duration: 10,
          rate: 2,
        },
        {
          duration: 11,
          rate: 2,
        },
        {
          duration: 12,
          rate: 2,
        },
        {
          duration: 18,
          rate: 2,
        },
        {
          duration: 24,
          rate: 2,
        },
      ],
      itemTypeIds: [39],
      availability: 'available',
    },
    {
      id: 1619,
      version: 12,
      slug: 'dressers-chelsea-chest-natural',
      name: 'Chelsea Chest, Natural',
      primaryImageAltText: '1619_Chelsea-Chest-Oak_Front',
      primaryImageListUrl:
        '//fernish.com/images/dcdb4e22195289010f6db4e4a3deb08c/medium/1619_Chelsea-Chest-Oak_front.jpg?1647372432',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 1299.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 3,
        inStock: 3,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 127,
        },
        {
          duration: 5,
          rate: 105,
        },
        {
          duration: 6,
          rate: 91,
        },
        {
          duration: 7,
          rate: 81,
        },
        {
          duration: 8,
          rate: 73,
        },
        {
          duration: 9,
          rate: 67,
        },
        {
          duration: 10,
          rate: 62,
        },
        {
          duration: 11,
          rate: 58,
        },
        {
          duration: 12,
          rate: 55,
        },
        {
          duration: 18,
          rate: 55,
        },
        {
          duration: 24,
          rate: 55,
        },
      ],
      itemTypeIds: [5],
      availability: 'available',
    },
    {
      id: 1778,
      version: 9,
      slug: 'consoles-tv-stands-cheney-media-console-white',
      name: 'Cheney Media Console, White',
      primaryImageAltText: '1778_Cheney-Media-Console-White_front',
      primaryImageListUrl:
        '//fernish.com/images/af3b3a1c59d38a9951eb2e36c2d94101/medium/1778_Cheney-Media-Console-White_front.jpg?1648737431',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 999.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 1,
        inStock: 1,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 98,
        },
        {
          duration: 5,
          rate: 81,
        },
        {
          duration: 6,
          rate: 70,
        },
        {
          duration: 7,
          rate: 62,
        },
        {
          duration: 8,
          rate: 56,
        },
        {
          duration: 9,
          rate: 52,
        },
        {
          duration: 10,
          rate: 48,
        },
        {
          duration: 11,
          rate: 45,
        },
        {
          duration: 12,
          rate: 42,
        },
        {
          duration: 18,
          rate: 42,
        },
        {
          duration: 24,
          rate: 42,
        },
      ],
      itemTypeIds: [27],
      availability: 'available',
    },
    {
      id: 1610,
      version: 12,
      slug: 'bedframes-cinder-bed-gray-full',
      name: 'Cinder Bed, Gray, Full',
      primaryImageAltText: '1609_Cinder-Bed-Gray-Queen_front_2022',
      primaryImageListUrl:
        '//fernish.com/images/505de8be6e24033b90ec88bf20ebfd64/medium/1609_Cinder-Bed-Gray-Queen_front_2022.jpg?1653264428',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 799.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 1,
        inStock: 1,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 78,
        },
        {
          duration: 5,
          rate: 65,
        },
        {
          duration: 6,
          rate: 56,
        },
        {
          duration: 7,
          rate: 50,
        },
        {
          duration: 8,
          rate: 45,
        },
        {
          duration: 9,
          rate: 41,
        },
        {
          duration: 10,
          rate: 38,
        },
        {
          duration: 11,
          rate: 36,
        },
        {
          duration: 12,
          rate: 34,
        },
        {
          duration: 18,
          rate: 34,
        },
        {
          duration: 24,
          rate: 34,
        },
      ],
      itemTypeIds: [16],
      availability: 'available',
    },
    {
      id: 136,
      version: 31,
      slug: 'outdoor-marina-outdoor-patio-teak-loveseat',
      name: 'Del Rey Patio Loveseat',
      primaryImageAltText: '136_Del-Rey-Patio-Loveseat_Flat-Front 2020',
      primaryImageListUrl:
        '//fernish.com/images/565148dff85e391bca37d6b7a0488eb9/medium/re_136_Del-Rey-Patio-Loveseat_Flat-Front_2020.jpg?1585190544',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 945.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 1,
        inStock: 1,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 92,
        },
        {
          duration: 5,
          rate: 77,
        },
        {
          duration: 6,
          rate: 66,
        },
        {
          duration: 7,
          rate: 59,
        },
        {
          duration: 8,
          rate: 53,
        },
        {
          duration: 9,
          rate: 49,
        },
        {
          duration: 10,
          rate: 45,
        },
        {
          duration: 11,
          rate: 43,
        },
        {
          duration: 12,
          rate: 40,
        },
        {
          duration: 18,
          rate: 40,
        },
        {
          duration: 24,
          rate: 40,
        },
      ],
      itemTypeIds: [22],
      availability: 'available',
    },
    {
      id: 1134,
      version: 23,
      slug: 'bedding-bathroom-eco-melange-duvet-cover-king-white',
      name: 'Eco Melange Duvet Cover, King, White',
      primaryImageAltText:
        '1121_Eco-Melange-Duvet-Cover-Queen-White_Front-Styled_2021',
      primaryImageListUrl:
        '//fernish.com/images/cb42a2cc04c994beba03ba44b43f7942/medium/1121_Eco-Melange-Duvet-Cover-Queen-White_Front-Styled_2021.jpg?1628621986',
      showWhenOutOfStock: true,
      merchandisingModel: 'purchase',
      retailPrice: 99.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 100,
        inStock: 100,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 15,
        },
        {
          duration: 5,
          rate: 13,
        },
        {
          duration: 6,
          rate: 11,
        },
        {
          duration: 7,
          rate: 10,
        },
        {
          duration: 8,
          rate: 10,
        },
        {
          duration: 9,
          rate: 9,
        },
        {
          duration: 10,
          rate: 9,
        },
        {
          duration: 11,
          rate: 9,
        },
        {
          duration: 12,
          rate: 9,
        },
        {
          duration: 18,
          rate: 9,
        },
        {
          duration: 24,
          rate: 9,
        },
      ],
      itemTypeIds: [34],
      availability: 'available',
    },
    {
      id: 675,
      version: 34,
      slug: 'floor-lamps-elly-floor-lamp',
      name: 'Elly Floor Lamp',
      primaryImageAltText:
        '675_Elly-Floor-Lamp_Right-3Q_california-chic_Living-Room-5 2020',
      primaryImageListUrl:
        '//fernish.com/images/73dbfb19fbf126ed3f45bc9d0fb90e8d/medium/re_675_Elly-Floor-Lamp_Right-3Q_california-chic_Living-Room-5_2020.jpg?1585202074',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 349.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 6,
        inStock: 6,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 34,
        },
        {
          duration: 5,
          rate: 29,
        },
        {
          duration: 6,
          rate: 25,
        },
        {
          duration: 7,
          rate: 22,
        },
        {
          duration: 8,
          rate: 20,
        },
        {
          duration: 9,
          rate: 18,
        },
        {
          duration: 10,
          rate: 17,
        },
        {
          duration: 11,
          rate: 16,
        },
        {
          duration: 12,
          rate: 15,
        },
        {
          duration: 18,
          rate: 15,
        },
        {
          duration: 24,
          rate: 15,
        },
      ],
      itemTypeIds: [35],
      availability: 'available',
    },
    {
      id: 35,
      version: 36,
      slug: 'side-tables-floyd-side-table-15',
      name: 'Floyd Side Table - 15"',
      primaryImageAltText:
        '35_Floyd-Side-Table-White-15in_Flat-Front_California-Chic_Bedroom-11 2020',
      primaryImageListUrl:
        '//fernish.com/images/c16bfd4f34744a0fcad056b985ad0e2a/medium/re_35_Floyd-Side-Table-White-15in_Flat-Front_California-Chic_Bedroom-11_2020.jpg?1585203784',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 149.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 1,
        inStock: 1,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 15,
        },
        {
          duration: 5,
          rate: 13,
        },
        {
          duration: 6,
          rate: 11,
        },
        {
          duration: 7,
          rate: 10,
        },
        {
          duration: 8,
          rate: 9,
        },
        {
          duration: 9,
          rate: 8,
        },
        {
          duration: 10,
          rate: 8,
        },
        {
          duration: 11,
          rate: 7,
        },
        {
          duration: 12,
          rate: 7,
        },
        {
          duration: 18,
          rate: 7,
        },
        {
          duration: 24,
          rate: 7,
        },
      ],
      itemTypeIds: [6, 8],
      availability: 'available',
    },
    {
      id: 676,
      version: 41,
      slug: 'lighting-hyde-brass-and-bronze-metal-tripod-floor-lamp',
      name: 'Hyde Brass and Bronze Metal Tripod Floor Lamp',
      primaryImageAltText:
        '676_Hyde-Brass-and-Bronze-Metal-Tripod-Floor-Lamp_Right-3Q_Industrial_Living-Room-4 2020',
      primaryImageListUrl:
        '//fernish.com/images/ab023453d27e3030c957bff97fd79c37/medium/re_676_Hyde-Brass-and-Bronze-Metal-Tripod-Floor-Lamp_Right-3Q_Industrial_Living-Room-4_2020.jpg?1585202117',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 300.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 11,
        inStock: 11,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 30,
        },
        {
          duration: 5,
          rate: 25,
        },
        {
          duration: 6,
          rate: 21,
        },
        {
          duration: 7,
          rate: 19,
        },
        {
          duration: 8,
          rate: 17,
        },
        {
          duration: 9,
          rate: 16,
        },
        {
          duration: 10,
          rate: 15,
        },
        {
          duration: 11,
          rate: 14,
        },
        {
          duration: 12,
          rate: 13,
        },
        {
          duration: 18,
          rate: 13,
        },
        {
          duration: 24,
          rate: 13,
        },
      ],
      itemTypeIds: [35],
      availability: 'available',
    },
    {
      id: 144,
      version: 32,
      slug: 'outdoor-dorset-outdoor-patio-teak-dining-table',
      name: 'Joudi Patio Dining Table',
      primaryImageAltText: '144_Joudi-Patio-Dining-Table_Flat-Front 2020',
      primaryImageListUrl:
        '//fernish.com/images/b78c3ca357cbaf36856878f2a9292bb0/medium/re_144_Joudi-Patio-Dining-Table_Flat-Front_2020.jpg?1585189425',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 1299.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 6,
        inStock: 6,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 127,
        },
        {
          duration: 5,
          rate: 105,
        },
        {
          duration: 6,
          rate: 91,
        },
        {
          duration: 7,
          rate: 81,
        },
        {
          duration: 8,
          rate: 73,
        },
        {
          duration: 9,
          rate: 67,
        },
        {
          duration: 10,
          rate: 62,
        },
        {
          duration: 11,
          rate: 58,
        },
        {
          duration: 12,
          rate: 55,
        },
        {
          duration: 18,
          rate: 55,
        },
        {
          duration: 24,
          rate: 55,
        },
      ],
      itemTypeIds: [22],
      availability: 'available',
    },
    {
      id: 912,
      version: 29,
      slug: 'desks-kendall-desk',
      name: 'Kendall Desk',
      primaryImageAltText: 'KendalDeskFron(2020)',
      primaryImageListUrl:
        '//fernish.com/images/52275212fe6367bf9662b5dd428daf15/medium/re_KendDesk_2_2020.jpg?1585203527',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 649.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 14,
        inStock: 14,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 64,
        },
        {
          duration: 5,
          rate: 53,
        },
        {
          duration: 6,
          rate: 46,
        },
        {
          duration: 7,
          rate: 41,
        },
        {
          duration: 8,
          rate: 37,
        },
        {
          duration: 9,
          rate: 34,
        },
        {
          duration: 10,
          rate: 31,
        },
        {
          duration: 11,
          rate: 29,
        },
        {
          duration: 12,
          rate: 28,
        },
        {
          duration: 18,
          rate: 28,
        },
        {
          duration: 24,
          rate: 28,
        },
      ],
      itemTypeIds: [21],
      availability: 'available',
    },
    {
      id: 1962,
      version: 10,
      slug: 'outdoor-lucene-indoor-outdoor-bench',
      name: 'Lucene Indoor / Outdoor Bench',
      primaryImageAltText: 'Lucene Indoor / Outdoor Bench',
      primaryImageListUrl:
        '//fernish.com/images/2e906c55832bd1ae0a48c1bb6c33fdd6/medium/data?1657199013',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 449.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 6,
        inStock: 6,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 44,
        },
        {
          duration: 5,
          rate: 37,
        },
        {
          duration: 6,
          rate: 32,
        },
        {
          duration: 7,
          rate: 28,
        },
        {
          duration: 8,
          rate: 25,
        },
        {
          duration: 9,
          rate: 23,
        },
        {
          duration: 10,
          rate: 22,
        },
        {
          duration: 11,
          rate: 20,
        },
        {
          duration: 12,
          rate: 19,
        },
        {
          duration: 18,
          rate: 19,
        },
        {
          duration: 24,
          rate: 19,
        },
      ],
      itemTypeIds: [22, 19],
      availability: 'available',
    },
    {
      id: 1181,
      version: 29,
      slug: 'sectionals-martell-sectional-blue-right-facing',
      name: 'Martell Sectional, Blue, Right Facing',
      primaryImageAltText: '1181_Martell-Sectional-RHF-Blue_front_2020',
      primaryImageListUrl:
        '//fernish.com/images/0e55b19a811030e9e579872490d71ee1/medium/1181_Martell-Sectional-RHF-Blue_front_2020.jpg?1615431130',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 2999.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 3,
        inStock: 3,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 292,
        },
        {
          duration: 5,
          rate: 242,
        },
        {
          duration: 6,
          rate: 209,
        },
        {
          duration: 7,
          rate: 186,
        },
        {
          duration: 8,
          rate: 167,
        },
        {
          duration: 9,
          rate: 154,
        },
        {
          duration: 10,
          rate: 143,
        },
        {
          duration: 11,
          rate: 134,
        },
        {
          duration: 12,
          rate: 126,
        },
        {
          duration: 18,
          rate: 126,
        },
        {
          duration: 24,
          rate: 126,
        },
      ],
      itemTypeIds: [76],
      availability: 'available',
    },
    {
      id: 206,
      version: 41,
      slug: 'office-chairs-midback-office-chair-black',
      name: 'Midback Office Chair, Black',
      primaryImageAltText: '206_Tremaine-Office-Chair-Black_Flat-Front 2020',
      primaryImageListUrl:
        '//fernish.com/images/1f2b0e41fbb8387c8394cb35ca529455/medium/re_206_Tremaine-Office-Chair-Black_Flat-Front_2020.jpg?1585191732',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 439.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 3,
        inStock: 3,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 43,
        },
        {
          duration: 5,
          rate: 36,
        },
        {
          duration: 6,
          rate: 31,
        },
        {
          duration: 7,
          rate: 28,
        },
        {
          duration: 8,
          rate: 25,
        },
        {
          duration: 9,
          rate: 23,
        },
        {
          duration: 10,
          rate: 21,
        },
        {
          duration: 11,
          rate: 20,
        },
        {
          duration: 12,
          rate: 19,
        },
        {
          duration: 18,
          rate: 19,
        },
        {
          duration: 24,
          rate: 19,
        },
      ],
      itemTypeIds: [26],
      availability: 'available',
    },
    {
      id: 1239,
      version: 15,
      slug: 'mirrors-wall-art-moonstone-framed-textile-22-x-22',
      name: 'Moonstone Framed Textile (22" x 22")',
      primaryImageAltText: '1239_Moonstone-Framed-Textile_Front_2021',
      primaryImageListUrl:
        '//fernish.com/images/4b904026a525609bed9a09e9981b6e0c/medium/1239_Moonstone-Framed-Textile_Front_2021.jpg?1618931430',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 199.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 5,
        inStock: 5,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 20,
        },
        {
          duration: 5,
          rate: 17,
        },
        {
          duration: 6,
          rate: 14,
        },
        {
          duration: 7,
          rate: 13,
        },
        {
          duration: 8,
          rate: 12,
        },
        {
          duration: 9,
          rate: 11,
        },
        {
          duration: 10,
          rate: 10,
        },
        {
          duration: 11,
          rate: 9,
        },
        {
          duration: 12,
          rate: 9,
        },
        {
          duration: 18,
          rate: 9,
        },
        {
          duration: 24,
          rate: 9,
        },
      ],
      itemTypeIds: [40],
      availability: 'available',
    },
    {
      id: 1119,
      version: 18,
      slug: 'desks-natalie-desk-black-marble-top-black-metal-base',
      name: 'Natalie Desk, Black Marble Top, Black Metal Base',
      primaryImageAltText: '1119_Natalie-Desk-Black-Marble-Top_Front_2021',
      primaryImageListUrl:
        '//fernish.com/images/0fe6d9f675f43aaafd35c2e466473207/medium/1119_Natalie-Desk-Black-Marble-Top_Front_2021.jpg?1624472449',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 149.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 2,
        inStock: 2,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 15,
        },
        {
          duration: 5,
          rate: 13,
        },
        {
          duration: 6,
          rate: 11,
        },
        {
          duration: 7,
          rate: 10,
        },
        {
          duration: 8,
          rate: 9,
        },
        {
          duration: 9,
          rate: 8,
        },
        {
          duration: 10,
          rate: 8,
        },
        {
          duration: 11,
          rate: 7,
        },
        {
          duration: 12,
          rate: 7,
        },
        {
          duration: 18,
          rate: 7,
        },
        {
          duration: 24,
          rate: 7,
        },
      ],
      itemTypeIds: [21],
      availability: 'available',
    },
    {
      id: 1329,
      version: 21,
      slug: 'sofas-sectionals-quincy-sofa-blue-tufted',
      name: 'Quincy Sofa, Blue, Tufted',
      primaryImageAltText: '1329_Quincy-Sofa-Blue-Tufted_Front_2021',
      primaryImageListUrl:
        '//fernish.com/images/c403592f4ddf2e0fd7a81d003b5b436f/medium/1329_Quincy-Sofa-Blue-Tufted_Front_2021.jpg?1624639225',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 2199.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 9,
        inStock: 9,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 214,
        },
        {
          duration: 5,
          rate: 178,
        },
        {
          duration: 6,
          rate: 153,
        },
        {
          duration: 7,
          rate: 136,
        },
        {
          duration: 8,
          rate: 123,
        },
        {
          duration: 9,
          rate: 113,
        },
        {
          duration: 10,
          rate: 105,
        },
        {
          duration: 11,
          rate: 98,
        },
        {
          duration: 12,
          rate: 93,
        },
        {
          duration: 18,
          rate: 93,
        },
        {
          duration: 24,
          rate: 93,
        },
      ],
      itemTypeIds: [76],
      availability: 'available',
    },
    {
      id: 1367,
      version: 14,
      slug: 'coffee-tables-quinn-coffee-table-walnut',
      name: 'Quinn Coffee Table, Walnut',
      primaryImageAltText: '1367_Quinn-Coffee-Table-Walnut_3Q_2021',
      primaryImageListUrl:
        '//fernish.com/images/ed893d47d2943d07af70c108f6c1a2a6/medium/1367_Quinn-Coffee-Table-Walnut_3Q_2021.jpg?1626897911',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 999.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 4,
        inStock: 4,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 98,
        },
        {
          duration: 5,
          rate: 81,
        },
        {
          duration: 6,
          rate: 70,
        },
        {
          duration: 7,
          rate: 62,
        },
        {
          duration: 8,
          rate: 56,
        },
        {
          duration: 9,
          rate: 52,
        },
        {
          duration: 10,
          rate: 48,
        },
        {
          duration: 11,
          rate: 45,
        },
        {
          duration: 12,
          rate: 42,
        },
        {
          duration: 18,
          rate: 42,
        },
        {
          duration: 24,
          rate: 42,
        },
      ],
      itemTypeIds: [7],
      availability: 'available',
    },
    {
      id: 1003,
      version: 18,
      slug: 'mirrors-wall-art-rachel-spirograph-mirror',
      name: 'Rachel Spirograph Mirror',
      primaryImageAltText: '1003_Rachel-Spirograph-Mirror_Front',
      primaryImageListUrl:
        '//fernish.com/images/4d2ae54291843f4d591d376a192965fd/medium/1003_Rachel-Spirograph-Mirror_Front.jpg?1598316482',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 200.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 3,
        inStock: 3,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 20,
        },
        {
          duration: 5,
          rate: 17,
        },
        {
          duration: 6,
          rate: 14,
        },
        {
          duration: 7,
          rate: 13,
        },
        {
          duration: 8,
          rate: 12,
        },
        {
          duration: 9,
          rate: 11,
        },
        {
          duration: 10,
          rate: 10,
        },
        {
          duration: 11,
          rate: 9,
        },
        {
          duration: 12,
          rate: 9,
        },
        {
          duration: 18,
          rate: 9,
        },
        {
          duration: 24,
          rate: 9,
        },
      ],
      itemTypeIds: [40],
      availability: 'available',
    },
    {
      id: 1191,
      version: 12,
      slug: 'mirrors-wall-art-raw-city-canvas-39-x-79',
      name: 'Raw City Canvas (39" x 79")',
      primaryImageAltText: '1191_Raw-City-Front_2020',
      primaryImageListUrl:
        '//fernish.com/images/fbb305d7b74e50922308d9204f442c8f/medium/1191_Raw-City-Front_2020.jpg?1616189684',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 399.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 2,
        inStock: 2,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 39,
        },
        {
          duration: 5,
          rate: 33,
        },
        {
          duration: 6,
          rate: 28,
        },
        {
          duration: 7,
          rate: 25,
        },
        {
          duration: 8,
          rate: 23,
        },
        {
          duration: 9,
          rate: 21,
        },
        {
          duration: 10,
          rate: 19,
        },
        {
          duration: 11,
          rate: 18,
        },
        {
          duration: 12,
          rate: 17,
        },
        {
          duration: 18,
          rate: 17,
        },
        {
          duration: 24,
          rate: 17,
        },
      ],
      itemTypeIds: [40],
      availability: 'available',
    },
    {
      id: 1474,
      version: 12,
      slug: 'ottomans-poufs-river-pouf',
      name: 'River Pouf',
      primaryImageAltText: '1474_River-Pouf_Front_2021',
      primaryImageListUrl:
        '//fernish.com/images/ab3228ab2ba1a236d00e288ce09afc11/medium/1474_River-Pouf_Front_2021.jpg?1632367058',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 299.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 6,
        inStock: 6,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 30,
        },
        {
          duration: 5,
          rate: 25,
        },
        {
          duration: 6,
          rate: 21,
        },
        {
          duration: 7,
          rate: 19,
        },
        {
          duration: 8,
          rate: 17,
        },
        {
          duration: 9,
          rate: 16,
        },
        {
          duration: 10,
          rate: 15,
        },
        {
          duration: 11,
          rate: 14,
        },
        {
          duration: 12,
          rate: 13,
        },
        {
          duration: 18,
          rate: 13,
        },
        {
          duration: 24,
          rate: 13,
        },
      ],
      itemTypeIds: [13],
      availability: 'available',
    },
    {
      id: 1827,
      version: 11,
      slug: 'outdoor-serena-outdoor-armchair-navy',
      name: 'Serena Outdoor Armchair, Navy',
      primaryImageAltText: '1827_Serena-Armchair-Navy_3Q-front',
      primaryImageListUrl:
        '//fernish.com/images/d1f8e232ad702b354edf26e4521f933c/medium/1827_Serena-Armchair-Navy_3Q-front.jpg?1650978414',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 649.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 10,
        inStock: 10,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 64,
        },
        {
          duration: 5,
          rate: 53,
        },
        {
          duration: 6,
          rate: 46,
        },
        {
          duration: 7,
          rate: 41,
        },
        {
          duration: 8,
          rate: 37,
        },
        {
          duration: 9,
          rate: 34,
        },
        {
          duration: 10,
          rate: 31,
        },
        {
          duration: 11,
          rate: 29,
        },
        {
          duration: 12,
          rate: 28,
        },
        {
          duration: 18,
          rate: 28,
        },
        {
          duration: 24,
          rate: 28,
        },
      ],
      itemTypeIds: [22],
      availability: 'available',
    },
    {
      id: 1829,
      version: 8,
      slug: 'outdoor-serena-outdoor-side-table',
      name: 'Serena Outdoor Side Table',
      primaryImageAltText: '1829_Serena-Outdoor-Side-Table_front',
      primaryImageListUrl:
        '//fernish.com/images/a187d6e040f9a2da4676e8254dda80da/medium/1829_Serena-Outdoor-Side-Table_front.jpg?1650507657',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 299.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 7,
        inStock: 7,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 30,
        },
        {
          duration: 5,
          rate: 25,
        },
        {
          duration: 6,
          rate: 21,
        },
        {
          duration: 7,
          rate: 19,
        },
        {
          duration: 8,
          rate: 17,
        },
        {
          duration: 9,
          rate: 16,
        },
        {
          duration: 10,
          rate: 15,
        },
        {
          duration: 11,
          rate: 14,
        },
        {
          duration: 12,
          rate: 13,
        },
        {
          duration: 18,
          rate: 13,
        },
        {
          duration: 24,
          rate: 13,
        },
      ],
      itemTypeIds: [22],
      availability: 'available',
    },
    {
      id: 1688,
      version: 16,
      slug: 'bedframes-skylar-bed-black-dash-full',
      name: 'Skylar Bed, Black Dash, Full',
      primaryImageAltText: 'XXXX_Skylar-Bedframe-Dash-Ink_front',
      primaryImageListUrl:
        '//fernish.com/images/4b82ea0d6a5617637a713eaf0573f4cc/medium/XXXX_Skylar-Bedframe-Dash-Ink_front.jpg?1648737851',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 1799.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 3,
        inStock: 3,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 175,
        },
        {
          duration: 5,
          rate: 146,
        },
        {
          duration: 6,
          rate: 125,
        },
        {
          duration: 7,
          rate: 112,
        },
        {
          duration: 8,
          rate: 101,
        },
        {
          duration: 9,
          rate: 93,
        },
        {
          duration: 10,
          rate: 86,
        },
        {
          duration: 11,
          rate: 81,
        },
        {
          duration: 12,
          rate: 76,
        },
        {
          duration: 18,
          rate: 76,
        },
        {
          duration: 24,
          rate: 76,
        },
      ],
      itemTypeIds: [16],
      availability: 'available',
    },
    {
      id: 1490,
      version: 12,
      slug: 'bedframes-skylar-bed-blush-king',
      name: 'Skylar Bed, Blush, King',
      primaryImageAltText: '1481_Skylar-Bed-Blush-Queen_Front_2021',
      primaryImageListUrl:
        '//fernish.com/images/1342ce8cff8b9019f6ba07a4884deca5/medium/1481_Skylar-Bed-Blush-Queen_Front_2021.jpg?1632517646',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 1599.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 4,
        inStock: 4,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 156,
        },
        {
          duration: 5,
          rate: 129,
        },
        {
          duration: 6,
          rate: 111,
        },
        {
          duration: 7,
          rate: 99,
        },
        {
          duration: 8,
          rate: 89,
        },
        {
          duration: 9,
          rate: 82,
        },
        {
          duration: 10,
          rate: 76,
        },
        {
          duration: 11,
          rate: 72,
        },
        {
          duration: 12,
          rate: 68,
        },
        {
          duration: 18,
          rate: 68,
        },
        {
          duration: 24,
          rate: 68,
        },
      ],
      itemTypeIds: [16],
      availability: 'available',
    },
    {
      id: 1486,
      version: 15,
      slug: 'bedframes-skylar-bed-seafoam-twin',
      name: 'Skylar Bed, Seafoam, Twin',
      primaryImageAltText: '1486_Skylar-Bed-Seafoam-Twin_Front_2021.jpg',
      primaryImageListUrl:
        '//fernish.com/images/57fb46d47be6daed7f253d6a377a5cd9/medium/1486_Skylar-Bed-Seafoam-Twin_Front_2021.jpg.jpg?1632368588',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 1199.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 2,
        inStock: 2,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 117,
        },
        {
          duration: 5,
          rate: 97,
        },
        {
          duration: 6,
          rate: 84,
        },
        {
          duration: 7,
          rate: 74,
        },
        {
          duration: 8,
          rate: 67,
        },
        {
          duration: 9,
          rate: 62,
        },
        {
          duration: 10,
          rate: 57,
        },
        {
          duration: 11,
          rate: 54,
        },
        {
          duration: 12,
          rate: 51,
        },
        {
          duration: 18,
          rate: 51,
        },
        {
          duration: 24,
          rate: 51,
        },
      ],
      itemTypeIds: [16],
      availability: 'available',
    },
    {
      id: 1347,
      version: 13,
      slug: 'dining-chairs-tami-dining-chair-oak',
      name: 'Tami Dining Chair, Oak',
      primaryImageAltText: '1347_Tami-Dining-Chair-Oak_Front_2021',
      primaryImageListUrl:
        '//fernish.com/images/90631269c1812b9ed047913c28eac61b/medium/1347_Tami-Dining-Chair-Oak_Front_2021.jpg?1626275587',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 399.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 7,
        inStock: 7,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 39,
        },
        {
          duration: 5,
          rate: 33,
        },
        {
          duration: 6,
          rate: 28,
        },
        {
          duration: 7,
          rate: 25,
        },
        {
          duration: 8,
          rate: 23,
        },
        {
          duration: 9,
          rate: 21,
        },
        {
          duration: 10,
          rate: 19,
        },
        {
          duration: 11,
          rate: 18,
        },
        {
          duration: 12,
          rate: 17,
        },
        {
          duration: 18,
          rate: 17,
        },
        {
          duration: 24,
          rate: 17,
        },
      ],
      itemTypeIds: [10],
      availability: 'available',
    },
    {
      id: 2095,
      version: 9,
      slug: 'dining-tables-thomas-dining-table-71',
      name: 'Thomas Dining Table (71")',
      primaryImageAltText: 'Thomas Dining Table (71")',
      primaryImageListUrl:
        '//fernish.com/images/eaadaab8670be5ed6df2e53db8f29069/medium/data?1665596466',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 899.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 2,
        inStock: 2,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 88,
        },
        {
          duration: 5,
          rate: 73,
        },
        {
          duration: 6,
          rate: 63,
        },
        {
          duration: 7,
          rate: 56,
        },
        {
          duration: 8,
          rate: 50,
        },
        {
          duration: 9,
          rate: 47,
        },
        {
          duration: 10,
          rate: 43,
        },
        {
          duration: 11,
          rate: 40,
        },
        {
          duration: 12,
          rate: 38,
        },
        {
          duration: 18,
          rate: 38,
        },
        {
          duration: 24,
          rate: 38,
        },
      ],
      itemTypeIds: [12],
      availability: 'available',
    },
    {
      id: 987,
      version: 28,
      slug: 'outdoor-tolliver-outdoor-armchair-red',
      name: 'Tolliver Outdoor Armchair, Red',
      primaryImageAltText: '987_Convene-Outdoor-Lounge-Red_3Q_2020',
      primaryImageListUrl:
        '//fernish.com/images/015e70d1a04f3874bcdadb895107a818/medium/987_Convene-Outdoor-Lounge-Red_3Q_2020.jpg?1592333696',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 499.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 6,
        inStock: 6,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 49,
        },
        {
          duration: 5,
          rate: 41,
        },
        {
          duration: 6,
          rate: 35,
        },
        {
          duration: 7,
          rate: 31,
        },
        {
          duration: 8,
          rate: 28,
        },
        {
          duration: 9,
          rate: 26,
        },
        {
          duration: 10,
          rate: 24,
        },
        {
          duration: 11,
          rate: 23,
        },
        {
          duration: 12,
          rate: 21,
        },
        {
          duration: 18,
          rate: 21,
        },
        {
          duration: 24,
          rate: 21,
        },
      ],
      itemTypeIds: [22],
      availability: 'available',
    },
    {
      id: 152,
      version: 42,
      slug: 'bar-stools-trattoria-30-bar-stool-powder-blue',
      name: 'Trattoria 30" Bar Stool, Powder Blue',
      primaryImageAltText: '152_TrattoriaBarStool-Blue-Front(2020)',
      primaryImageListUrl:
        '//fernish.com/images/206a1b671e79070e44c6c4f57802814f/medium/re_152_Trattoria-30in-Bar-Stool-Powder-Blue_Front_2020.jpg?1585204720',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 69.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 4,
        inStock: 4,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 7,
        },
        {
          duration: 5,
          rate: 6,
        },
        {
          duration: 6,
          rate: 5,
        },
        {
          duration: 7,
          rate: 5,
        },
        {
          duration: 8,
          rate: 4,
        },
        {
          duration: 9,
          rate: 4,
        },
        {
          duration: 10,
          rate: 4,
        },
        {
          duration: 11,
          rate: 4,
        },
        {
          duration: 12,
          rate: 3,
        },
        {
          duration: 18,
          rate: 3,
        },
        {
          duration: 24,
          rate: 3,
        },
      ],
      itemTypeIds: [11],
      availability: 'available',
    },
    {
      id: 199,
      version: 30,
      slug: 'dining-chairs-trattoria-arm-chair-distressed-white',
      name: 'Trattoria Arm Chair, Distressed White',
      primaryImageAltText:
        '199_Trattoria-Arm-Chair-Distressed-White_Flat-Front 2020',
      primaryImageListUrl:
        '//fernish.com/images/cf3df7358a2dba0820fcc1a56cf01fee/medium/re_199_Trattoria-Arm-Chair-Distressed-White_Flat-Front_2020.jpg?1585202793',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 69.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 2,
        inStock: 2,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 7,
        },
        {
          duration: 5,
          rate: 6,
        },
        {
          duration: 6,
          rate: 5,
        },
        {
          duration: 7,
          rate: 5,
        },
        {
          duration: 8,
          rate: 4,
        },
        {
          duration: 9,
          rate: 4,
        },
        {
          duration: 10,
          rate: 4,
        },
        {
          duration: 11,
          rate: 4,
        },
        {
          duration: 12,
          rate: 3,
        },
        {
          duration: 18,
          rate: 3,
        },
        {
          duration: 24,
          rate: 3,
        },
      ],
      itemTypeIds: [10],
      availability: 'available',
    },
    {
      id: 413,
      version: 36,
      slug: 'dining-chairs-trattoria-side-chair-black',
      name: 'Trattoria Side Chair, Black',
      primaryImageAltText:
        '413_Trattoria-Side-Chair-Black_Flat-Front_Industrial_Dining-Room-16 2020',
      primaryImageListUrl:
        '//fernish.com/images/617794ce25630149abb29dd186e64520/medium/re_413_Trattoria-Side-Chair-Black_Flat-Front_Industrial_Dining-Room-16_2020.jpg?1585199152',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 69.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 3,
        inStock: 3,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 7,
        },
        {
          duration: 5,
          rate: 6,
        },
        {
          duration: 6,
          rate: 5,
        },
        {
          duration: 7,
          rate: 5,
        },
        {
          duration: 8,
          rate: 4,
        },
        {
          duration: 9,
          rate: 4,
        },
        {
          duration: 10,
          rate: 4,
        },
        {
          duration: 11,
          rate: 4,
        },
        {
          duration: 12,
          rate: 3,
        },
        {
          duration: 18,
          rate: 3,
        },
        {
          duration: 24,
          rate: 3,
        },
      ],
      itemTypeIds: [10],
      availability: 'available',
    },
    {
      id: 202,
      version: 30,
      slug: 'dining-chairs-vortex-side-chair-teal',
      name: 'Vortex Side Chair, Teal',
      primaryImageAltText: '202_Vortex-Side-Chair-Teal_Flat-Front 2020',
      primaryImageListUrl:
        '//fernish.com/images/d2907ef20b2c44f824a151c6cf1b8fb4/medium/re_202_Vortex-Side-Chair-Teal_Flat-Front_2020.jpg?1585190633',
      showWhenOutOfStock: false,
      merchandisingModel: 'subscription',
      retailPrice: 69.0,
      display: true,
      quantityDetails: {
        backorders: [],
        available: 12,
        inStock: 12,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 7,
        },
        {
          duration: 5,
          rate: 6,
        },
        {
          duration: 6,
          rate: 5,
        },
        {
          duration: 7,
          rate: 5,
        },
        {
          duration: 8,
          rate: 4,
        },
        {
          duration: 9,
          rate: 4,
        },
        {
          duration: 10,
          rate: 4,
        },
        {
          duration: 11,
          rate: 4,
        },
        {
          duration: 12,
          rate: 3,
        },
        {
          duration: 18,
          rate: 3,
        },
        {
          duration: 24,
          rate: 3,
        },
      ],
      itemTypeIds: [10],
      availability: 'available',
    },
    {
      id: 1200,
      version: 23,
      slug: 'mattresses-aveline-king-mattress-10',
      name: 'Aveline King Mattress (10")',
      primaryImageAltText: '1176_Aveline-Queen-Mattress-8in_Front',
      primaryImageListUrl:
        '//fernish.com/images/efe00e3b48b7eb9387c17ac2ac703d82/medium/1176_Aveline-Queen-Mattress-8in_Front.jpg?1653333377',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 999.0,
      display: false,
      quantityDetails: {
        backorders: [],
        available: 0,
        inStock: 0,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 149,
        },
        {
          duration: 5,
          rate: 127,
        },
        {
          duration: 6,
          rate: 111,
        },
        {
          duration: 7,
          rate: 100,
        },
        {
          duration: 8,
          rate: 92,
        },
        {
          duration: 9,
          rate: 85,
        },
        {
          duration: 10,
          rate: 84,
        },
        {
          duration: 11,
          rate: 84,
        },
        {
          duration: 12,
          rate: 84,
        },
        {
          duration: 18,
          rate: 84,
        },
        {
          duration: 24,
          rate: 84,
        },
      ],
      itemTypeIds: [15],
      availability: 'tempOutOfStock',
    },
    {
      id: 248,
      version: 40,
      slug: 'accent-chairs-colton-occasional-chair',
      name: 'Colton Occasional Chair',
      primaryImageAltText:
        '248_Colton-Occasional-Chair_Flat-Front_Industrial_Living-Room-4 2020',
      primaryImageListUrl:
        '//fernish.com/images/208dd357a4d4d8d22ae564a8a07790a9/medium/re_248_Colton-Occasional-Chair_Flat-Front_Industrial_Living-Room-4_2020.jpg?1585195579',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 799.0,
      display: false,
      quantityDetails: {
        backorders: [],
        available: 0,
        inStock: 0,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 78,
        },
        {
          duration: 5,
          rate: 65,
        },
        {
          duration: 6,
          rate: 56,
        },
        {
          duration: 7,
          rate: 50,
        },
        {
          duration: 8,
          rate: 45,
        },
        {
          duration: 9,
          rate: 41,
        },
        {
          duration: 10,
          rate: 38,
        },
        {
          duration: 11,
          rate: 36,
        },
        {
          duration: 12,
          rate: 34,
        },
        {
          duration: 18,
          rate: 34,
        },
        {
          duration: 24,
          rate: 34,
        },
      ],
      itemTypeIds: [2],
      availability: 'tempOutOfStock',
    },
    {
      id: 1593,
      version: 13,
      slug: 'mirrors-wall-art-dove-canvas-wall-art-24-x-32',
      name: 'Dove Canvas Wall Art  (24" x 32") ',
      primaryImageAltText: '1593_Dove-Canvas-Wall-Art_front_2021',
      primaryImageListUrl:
        '//fernish.com/images/6a5b9e190abd502c5d7b1959c2218994/medium/1593_Dove-Canvas-Wall-Art_front_2021.jpg?1641848655',
      showWhenOutOfStock: true,
      merchandisingModel: 'subscription',
      retailPrice: 199.0,
      display: false,
      quantityDetails: {
        backorders: [],
        available: 0,
        inStock: 0,
        replenishable: true,
      },
      monthlyRates: [
        {
          duration: 4,
          rate: 20,
        },
        {
          duration: 5,
          rate: 17,
        },
        {
          duration: 6,
          rate: 14,
        },
        {
          duration: 7,
          rate: 13,
        },
        {
          duration: 8,
          rate: 12,
        },
        {
          duration: 9,
          rate: 11,
        },
        {
          duration: 10,
          rate: 10,
        },
        {
          duration: 11,
          rate: 9,
        },
        {
          duration: 12,
          rate: 9,
        },
        {
          duration: 18,
          rate: 9,
        },
        {
          duration: 24,
          rate: 9,
        },
      ],
      itemTypeIds: [40],
      availability: 'tempOutOfStock',
    },
  ],
  filter_counts: {
    materials: [],
    rug_sizes: [],
    bed_sizes: [],
    room_types: [
      {
        id: 56,
        name: 'bedroom',
        slug: 'bedroom',
        qty: 150,
      },
      {
        id: 57,
        name: 'living room',
        slug: 'living-room',
        qty: 145,
      },
      {
        id: 58,
        name: 'dining',
        slug: 'dining-room',
        qty: 85,
      },
      {
        id: 306,
        name: 'offices',
        slug: 'offices',
        qty: 30,
      },
    ],
    item_types: [
      {
        id: 37,
        name: 'Pillows & Throws',
        slug: 'pillows-throws',
        qty: 70,
      },
      {
        id: 16,
        name: 'Bedframes',
        slug: 'bedframes',
        qty: 65,
      },
      {
        id: 230,
        name: 'Mirrors',
        slug: 'mirrors',
        qty: 22,
      },
      {
        id: 231,
        name: 'Wall Art',
        slug: 'wall-art',
        qty: 33,
      },
      {
        id: 38,
        name: 'Rugs',
        slug: 'rugs',
        qty: 53,
      },
      {
        id: 76,
        name: 'Sofas & Sectionals',
        slug: 'sofas-sectionals',
        qty: 49,
      },
      {
        id: 8,
        name: 'Nightstands',
        slug: 'nightstands',
        qty: 38,
      },
      {
        id: 10,
        name: 'Dining Chairs',
        slug: 'dining-chairs',
        qty: 37,
      },
      {
        id: 35,
        name: 'Lighting',
        slug: 'lighting',
        qty: 37,
      },
      {
        id: 5,
        name: 'Dressers',
        slug: 'dressers',
        qty: 29,
      },
      {
        id: 22,
        name: 'Outdoor',
        slug: 'outdoor',
        qty: 26,
      },
    ],
    color_families: [
      {
        id: 519,
        name: 'brown/tan',
        slug: 'brown',
        qty: 165,
      },
      {
        id: 520,
        name: 'black',
        slug: 'black',
        qty: 116,
      },
      {
        id: 524,
        name: 'white',
        slug: 'white',
        qty: 102,
      },
      {
        id: 523,
        name: 'grey',
        slug: 'grey',
        qty: 88,
      },
      {
        id: 1023,
        name: 'beige',
        slug: 'beige',
        qty: 76,
      },
      {
        id: 522,
        name: 'blue',
        slug: 'blue',
        qty: 66,
      },
      {
        id: 530,
        name: 'wood_color family',
        slug: 'medium-wood',
        qty: 66,
      },
      {
        id: 1629,
        name: 'light wood',
        slug: 'light-wood',
        qty: 32,
      },
      {
        id: 528,
        name: 'red',
        slug: 'red',
        qty: 25,
      },
      {
        id: 537,
        name: 'green color family',
        slug: 'green-261177f0-3dc3-4ec7-8c61-f8a751c04d2a',
        qty: 18,
      },
    ],
  },
});
