const _mockLocalStorageData: Record<string, string | null> = {};

const mockLocalStorage = {
  getItem(key: string) {
    return _mockLocalStorageData[key];
  },
  setItem(key: string, value: string) {
    _mockLocalStorageData[key] = value;
  },
};

function getLocalStorage() {
  if (localStorage) {
    return localStorage;
  } else {
    // sometimes browsers in private mode don't allow localStorage
    // maybe this doesn't happy anymore but it definitely used to
    return mockLocalStorage;
  }
}

function getItem(key: string): string | null {
  return getLocalStorage().getItem(key);
}

function setItem(key: string, value: string): void {
  getLocalStorage().setItem(key, value);
}

function clear() {
  localStorage?.clear();
}

export const LocalStorageService = { setItem, getItem, clear };
