import {
  FilteredItemFilters,
  ItemService,
  OtherFilters,
} from '@services/item_service/item_service';
import { useInfiniteQuery } from 'react-query';

export const FilteredItemsKey = 'Filtered Items';

export function useFilteredItems(
  filters: FilteredItemFilters,
  otherFilters: OtherFilters
) {
  return useInfiniteQuery(
    [FilteredItemsKey, { filters, otherFilters }],
    ({ pageParam }) => {
      return ItemService.getFilteredItems(pageParam, filters, otherFilters);
    },
    {
      staleTime: 1000 * 60 * 5, // every five minutes
      getNextPageParam(lastPage, _allPages) {
        const { page, page_count } = lastPage.query_params;
        if (page < page_count) {
          return page + 1;
        } else {
          return undefined;
        }
      },
      getPreviousPageParam(firstPage, _allPages) {
        const { page } = firstPage.query_params;
        if (page > 1) {
          return page - 1;
        } else {
          return undefined;
        }
      },
    }
  );
}
