import { makeMock } from '../utility_functions/makeMock';
import { Image } from './image.interface';
import { ItemType } from './item_type.interface';
import { MerchandisingModel } from './merchandising_model.type';
import { Availability } from './availability.type';
import { MonthlyRate } from './monthly_rate.interface';
import { QuantityDetailsForItem } from './quantity_details.interface';

export interface ItemWithoutDisplayData {
  id: number;
  version: number;
  slug: string;
  name: string;
  primaryImageAltText: string;
  primaryImageListUrl: string;
  merchandisingModel: MerchandisingModel;
  retailPrice: number;
  monthlyRates?: MonthlyRate[];
}

export function getLilypadWithoutDisplayData(): ItemWithoutDisplayData {
  return getItemWithoutDisplayData({
    id: 1,
    name: 'Paired Lilypad',
    primaryImageListUrl: 'https://placekitten.com/300/301',
  });
}

export function getCouchWithoutDisplayData(): ItemWithoutDisplayData {
  return getItemWithoutDisplayData({
    id: 2,
    name: 'Campaign Couch',
    primaryImageListUrl: 'https://placekitten.com/300/300',
  });
}

export const getItemWithoutDisplayData = makeMock<ItemWithoutDisplayData>({
  id: 1,
  version: 1,
  slug: 'couch-modern-sofa-rouge',
  name: 'Modern Sofa, Rouge',
  primaryImageAltText: 'Loveseat',
  primaryImageListUrl: 'https://placekitten.com/300/300',
  merchandisingModel: 'subscription',
  retailPrice: 2000,
});

export interface Item extends ItemWithoutDisplayData {
  showWhenOutOfStock: boolean;
  display: boolean;
  quantityDetails: QuantityDetailsForItem;
  availability: Availability;
}

export const mockItem = makeMock<Item>({
  id: 1,
  version: 1,
  slug: 'couch-modern-sofa-rouge',
  name: 'Modern Sofa, Rouge',
  primaryImageAltText: 'Loveseat',
  primaryImageListUrl: 'https://placekitten.com/300/300',
  merchandisingModel: 'subscription',
  retailPrice: 2000,
  showWhenOutOfStock: false,
  display: true,
  quantityDetails: {
    available: 10,
    inStock: 10,
    backorders: [],
    replenishable: true,
  },
  availability: 'available',
});

export interface ItemDetails extends Item {
  // from columns on items table
  id: number;
  baseSku: string | null;
  chaiseDepth: number | null;
  chaiseDepthUom: LengthUnit | null;
  clearance: number | null;
  clearanceUom: LengthUnit | null;
  deleted: boolean;
  depth: string;
  depthUom: LengthUnit;
  description: string;
  discontinued: boolean;
  durability: number;
  height: string;
  heightUom: LengthUnit;
  heightRange: number | null;
  heightRangeUom: LengthUnit | null;
  hpCarousel: boolean;
  itemTypeVersion: number;
  merchandisingModel: MerchandisingModel;
  name: string;
  nonChaiseDepth: number | null;
  nonChaiseDepthUom: LengthUnit | null;
  notes: string | null;
  priceAdjust: number | null;
  primaryImageAltText: string;
  primaryImageListUrl: string;
  retailPrice: number;
  seatHeight: number | null;
  seatHeightUom: LengthUnit | null;
  showWhenOutOfStock: boolean;
  slug: string;
  snippet: string | null;
  supplierItemName: string;
  supplierSku: string | null;
  tips: string | null;
  version: number;
  weight: number | null;
  weightUom: WeightUnit;
  whatToKnow: string | null;
  width: string;
  widthUom: LengthUnit;
  createdAt: string;
  updatedAt: string;
  itemTypeId: number;
  pairedItemId: number;
  supplierId: number;
  video: {
    thumbnail_url: string | null;
    video_url: string | null;
  };

  // from items relationships
  display: boolean;
  monthlyRates: MonthlyRate[];
  images: Image[];
  dimensionImageUrl: string;
  itemType: ItemType;
  variants: Variant[];
  renovaiSku: number;
}

export interface Variant {
  slug: string;
  dimensions: Dimensions;
  temporarilyOutOfStock: boolean;
}

export interface Dimensions {
  color?: Color;
  alignment?: string;
  size?: string;
}

export interface Color {
  name: string;
  hexCode?: string;
  swatchUrl?: string;
}

export type LengthUnit = 'in' | 'cm' | 'ft';
export type WeightUnit = 'lb' | 'kg';

export const mockItemDetails = makeMock<ItemDetails>({
  availability: 'available',
  dimensionImageUrl:
    'https://staging.fernish.dev/media/aKM6VNhPMLYb6FboyijEBCyL/medium/35_Floyd-Side-Table-White-15in_Flat-Front_California-Chic_Bedroom-11_dimensions.png',
  display: true,
  images: [
    {
      listUrl:
        'https://staging.fernish.dev/media/4bv3aBJX8tWzrCHaZShUjri1/medium/Bedroom_38_CalChic_Low_Props_Detail-2.jpg',
      thumbnailUrl:
        'https://staging.fernish.dev/media/4bv3aBJX8tWzrCHaZShUjri1/thumb/Bedroom_38_CalChic_Low_Props_Detail-2.jpg',
      url: 'https://staging.fernish.dev/media/4bv3aBJX8tWzrCHaZShUjri1/large/Bedroom_38_CalChic_Low_Props_Detail-2.jpg',
      altText: 'Bedroom_38_CalChic_Low_Props_Detail-2',
    },
    {
      listUrl:
        'https://staging.fernish.dev/media/QkPB3NmPmqAkr82TCb6vmJr8/medium/data',
      thumbnailUrl:
        'https://staging.fernish.dev/media/QkPB3NmPmqAkr82TCb6vmJr8/thumb/data',
      url: 'https://staging.fernish.dev/media/QkPB3NmPmqAkr82TCb6vmJr8/large/data',
      altText: 'Bedroom_38_CalChic_Low_Props_Detail',
    },
    {
      listUrl:
        'https://staging.fernish.dev/media/59YLoUdnyiAokdAgwJ25ia6w/medium/data',
      thumbnailUrl:
        'https://staging.fernish.dev/media/59YLoUdnyiAokdAgwJ25ia6w/thumb/data',
      url: 'https://staging.fernish.dev/media/59YLoUdnyiAokdAgwJ25ia6w/large/data',
      altText: 'Bedroom_38_CalChic_Low_Props_Front',
    },
    {
      listUrl:
        'https://staging.fernish.dev/media/TnvW4buBLA7q578bx6D4Raj3/medium/re_35_Floyd-Side-Table-White-15in_Flat-Front_California-Chic_Bedroom-11_2020.jpg',
      thumbnailUrl:
        'https://staging.fernish.dev/media/TnvW4buBLA7q578bx6D4Raj3/thumb/re_35_Floyd-Side-Table-White-15in_Flat-Front_California-Chic_Bedroom-11_2020.jpg',
      url: 'https://staging.fernish.dev/media/TnvW4buBLA7q578bx6D4Raj3/large/re_35_Floyd-Side-Table-White-15in_Flat-Front_California-Chic_Bedroom-11_2020.jpg',
      altText:
        '35_Floyd-Side-Table-White-15in_Flat-Front_California-Chic_Bedroom-11 (2020)',
    },
  ],
  itemType: {
    id: 6,
    name: 'Side Tables',
    slug: 'side-tables',
    namePlural: 'Side Tables',
    iconUrl: null,
    imageUrl:
      'https://staging.fernish.dev/media/hZPNbTMBv6HHSe4yFa7qivEp/large/re_684_Strand-Magazine-Stand_Right-3Q_Industrial_Living-Room-3_2020.jpg',
  },
  monthlyRates: [
    { rate: 9, duration: 12 },
    { rate: 10, duration: 11 },
    { rate: 10, duration: 10 },
    { rate: 11, duration: 9 },
    { rate: 12, duration: 8 },
    { rate: 13, duration: 7 },
    { rate: 15, duration: 6 },
    { rate: 17, duration: 5 },
    { rate: 20, duration: 4 },
  ],
  primaryImageAltText: 'Bedroom_38_CalChic_Low_Props_Detail-2',
  primaryImageListUrl:
    'https://staging.fernish.dev/media/4bv3aBJX8tWzrCHaZShUjri1/medium/Bedroom_38_CalChic_Low_Props_Detail-2.jpg',
  quantityDetails: {
    inStock: 8,
    available: 8,
    backorders: [],
    replenishable: true,
  },
  renovaiSku: 35,
  variants: [
    {
      slug: 'side-tables-floyd-side-table-15',
      dimensions: {
        size: 'King',
        color: { name: 'millennial pink', hexCode: 'FF99FF' },
      },
      temporarilyOutOfStock: false,
    },
    {
      slug: 'sofas-campaign-sofa-dusty-rose',
      dimensions: { color: { name: 'dusty pink', hexCode: 'FF99CC' } },
      temporarilyOutOfStock: false,
    },
    {
      slug: 'sofas-campaign-sofa-flint-grey',
      dimensions: {
        color: {
          name: "men's suit",
          swatchUrl:
            'https://staging.fernish.dev/media/J7249T9abkKNQUdKs9v78AGn/small/alpine_cheney_bench_detail2.jpg',
        },
      },
      temporarilyOutOfStock: false,
    },
    {
      slug: 'sofas-campaign-sofa-flint-grey',
      dimensions: {
        color: {
          name: "men's suit",
          swatchUrl:
            'https://staging.fernish.dev/media/p6RKqeSbYVKZ7G1PBp1kry2q/small/alpine_cheney_bench_detail2.jpg',
        },
      },
      temporarilyOutOfStock: false,
    },
    {
      slug: 'sofas-campaign-sofa-midnight-navy',
      dimensions: { color: { name: 'desert orange', hexCode: 'FF9900' } },
      temporarilyOutOfStock: false,
    },
    {
      slug: 'sofas-campaign-sofa-mojave-orange',
      dimensions: { color: { name: 'mid-century orange', hexCode: 'FF9933' } },
      temporarilyOutOfStock: false,
    },
  ],
  id: 35,
  baseSku: 'FT-S15-GYWH',
  chaiseDepth: null,
  chaiseDepthUom: null,
  clearance: null,
  clearanceUom: null,
  deleted: false,
  depth: '15.0',
  depthUom: 'in',
  description:
    'The Floyd Side Table is versatility at its finest. Sturdy birch ply, steel legs, and a beautiful natural linoleum surface enable this “table-for-wherever” to become everything from your perfect accent piece to your favorite ad hoc stool. ',
  discontinued: false,
  durability: 3,
  height: '17.5',
  heightUom: 'in',
  heightRange: null,
  heightRangeUom: null,
  hpCarousel: false,
  itemTypeVersion: 8,
  nonChaiseDepth: null,
  nonChaiseDepthUom: null,
  notes: '',
  priceAdjust: null,
  seatHeight: null,
  seatHeightUom: null,
  snippet: '',
  tips: null,
  supplierItemName: '',
  supplierSku: '',
  weight: null,
  weightUom: 'lb',
  whatToKnow: 'The table top has a fog gray finish',
  width: '15.0',
  widthUom: 'in',
  createdAt: '2018-03-03T21:07:44.109-08:00',
  updatedAt: '2022-05-24T16:40:12.170-07:00',
  itemTypeId: 6,
  pairedItemId: 83,
  supplierId: 3,
  version: 20,
  slug: 'side-tables-floyd-side-table-15',
  name: 'Floyd Side Table - 15"',
  showWhenOutOfStock: false,
  merchandisingModel: 'subscription',
  retailPrice: 205.0,
  video: {
    video_url:
      'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-1080p.mp4',
    thumbnail_url: 'https://placekitten.com/600/300',
  },
});
