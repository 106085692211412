import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

const QueryKey = 'OnScrollOrClick';

export function useOnScrollOrClick(init?: () => void) {
  const queryClient = useQueryClient();
  const { data: scrolled } = useQuery(QueryKey, () => false, {
    staleTime: 1000 * 999999999, // basically never go stale
  });

  useEffect(() => {
    function onInteraction() {
      queryClient.setQueryData(QueryKey, true);

      if (init) {
        init();
      }

      cleanup();
    }

    function cleanup() {
      window.removeEventListener('scroll', onInteraction);
      window.removeEventListener('click', onInteraction);
    }

    window.addEventListener('scroll', onInteraction);
    window.addEventListener('click', onInteraction);

    return cleanup;
  }, []);

  return scrolled;
}
