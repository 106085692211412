import { ItemDetails } from '@interfaces/item.interface';
import { ItemService } from '@services/item_service/item_service';
import { useQuery } from 'react-query';

export const ItemDetailsBaseKey = ['Item Details'];
const ItemDetailsKey = (slug: string | string[]) => [
  ...ItemDetailsBaseKey,
  slug,
];

export function useItem(slug: string) {
  const query = useQuery<ItemDetails, Error>(
    ItemDetailsKey(slug),
    () => ItemService.getItem(slug),
    {
      staleTime: 1000 * 30, // every 30 seconds
    }
  );
  return query;
}
