import { useOnScrollOrClick } from '@custom_hooks/use_on_scroll_or_click';
import { FunctionComponent, ReactNode } from 'react';

export const LoadOnInteraction: FunctionComponent<{
  init?: () => void;
  loading?: ReactNode;
}> = ({ children, init, loading }) => {
  const scrolled = useOnScrollOrClick(init);

  if (scrolled) {
    return <>{children}</>;
  } else if (loading) {
    return <>{loading}</>;
  } else {
    return <></>;
  }
};
