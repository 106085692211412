import { QuantityDetailsForCart } from './quantity_details.interface';
import { MonthlyRate } from './monthly_rate.interface';
import { MerchandisingModel } from './merchandising_model.type';
import { makeMock } from '@utility_functions/makeMock';

export interface CartDetail {
  marketId: number;
  maximumItemQuantity: number;
  minimumTotal: number;
  monthlyRates: MonthlyRate[];
  monthlyTotal: number;
  purchaseItemsSubtotal: number;
  subscriptionDuration: number;
  subscriptionLineItems: CartLineItemDetail[];
  totalContractValue: number;
  purchaseLineItems: CartLineItemDetail[];
}

export interface CartLineItemDetail {
  discontinued: boolean;
  discountRate: number;
  display: boolean;
  itemId: number;
  lineItemId: number;
  merchandisingModel: MerchandisingModel;
  monthlyRate: number;
  name: string;
  primaryImageListUrl: string;
  primaryImageThumbnailUrl: string;
  primaryImageUrl: string;
  quantity: number;
  quantityDetails: QuantityDetailsForCart;
  retailPrice: string; // TODO - can this be a number?
  slug: string;
}

export const mockCartDetail = makeMock<CartDetail>({
  marketId: 1,
  maximumItemQuantity: 8,
  minimumTotal: 75,
  monthlyRates: [
    { duration: 2, rate: 55 },
    { duration: 3, rate: 37 },
    { duration: 4, rate: 28 },
    { duration: 5, rate: 28 },
    { duration: 6, rate: 28 },
    { duration: 7, rate: 20 },
    { duration: 8, rate: 20 },
    { duration: 9, rate: 20 },
    { duration: 10, rate: 15 },
    { duration: 11, rate: 15 },
    { duration: 12, rate: 15 },
    { duration: 18, rate: 15 },
    { duration: 24, rate: 15 },
  ],
  monthlyTotal: 6.0,
  purchaseItemsSubtotal: 1508.0,
  purchaseLineItems: [
    {
      discontinued: false,
      discountRate: 64,
      display: true,
      itemId: 430,
      lineItemId: 481736,
      merchandisingModel: 'purchase',
      monthlyRate: 64,
      name: 'Blake Sofa',
      primaryImageListUrl:
        'http://staging.fernish.dev/images/4998b4ec7a9eb58926686476d33be633/medium/re_430_Blake-Sofa_Flat-Front_2020.jpg?1583284803',
      primaryImageThumbnailUrl:
        'http://staging.fernish.dev/images/4998b4ec7a9eb58926686476d33be633/thumb/re_430_Blake-Sofa_Flat-Front_2020.jpg?1583284803',
      primaryImageUrl:
        'http://staging.fernish.dev/images/4998b4ec7a9eb58926686476d33be633/large/re_430_Blake-Sofa_Flat-Front_2020.jpg?1583284803',
      quantity: 1,
      quantityDetails: {
        allocation: 1,
        available: 8,
        backorders: [],
        inStock: 8,
        unavailable: 0,
      },
      retailPrice: '1508.00',
      slug: 'sofas-blake-sofa',
    },
  ],
  subscriptionDuration: 12,
  subscriptionLineItems: [
    {
      discontinued: false,
      discountRate: 6,
      display: true,
      itemId: 1,
      lineItemId: 481631,
      merchandisingModel: 'subscription',
      monthlyRate: 6,
      name: 'Drum Table Lamp, Charcoal',
      primaryImageListUrl:
        'http://staging.fernish.dev/images/fbcb1e216a346db1976a49ad521f4f57/medium/re_1_Drum-Table-Lamp-Charcoal_Flat-Front_2020.jpg?1584657964',
      primaryImageThumbnailUrl:
        'http://staging.fernish.dev/images/fbcb1e216a346db1976a49ad521f4f57/thumb/re_1_Drum-Table-Lamp-Charcoal_Flat-Front_2020.jpg?1584657964',
      primaryImageUrl:
        'http://staging.fernish.dev/images/fbcb1e216a346db1976a49ad521f4f57/large/re_1_Drum-Table-Lamp-Charcoal_Flat-Front_2020.jpg?1584657964',
      quantity: 1,
      quantityDetails: {
        allocation: 1,
        available: 8,
        backorders: [],
        inStock: 8,
        unavailable: 0,
      },
      retailPrice: '130.00',
      slug: 'table-lamps-drum-table-lamp-black',
    },
    {
      discontinued: true,
      discountRate: 7,
      display: false,
      itemId: 4,
      lineItemId: 481632,
      merchandisingModel: 'subscription',
      monthlyRate: 9,
      name: 'Iggy Table Lamp',
      primaryImageListUrl:
        'http://staging.fernish.dev/images/61bed2682664552385174e7bb3e5c55d/medium/eq3_iggy_table_lamp_on_front_02.jpg?1521587957',
      primaryImageThumbnailUrl:
        'http://staging.fernish.dev/images/61bed2682664552385174e7bb3e5c55d/thumb/eq3_iggy_table_lamp_on_front_02.jpg?1521587957',
      primaryImageUrl:
        'http://staging.fernish.dev/images/61bed2682664552385174e7bb3e5c55d/large/eq3_iggy_table_lamp_on_front_02.jpg?1521587957',
      quantity: 1,
      quantityDetails: {
        allocation: 1,
        available: 8,
        backorders: [],
        inStock: 8,
        unavailable: 0,
      },
      retailPrice: '199.00',
      slug: 'table-lamps-iggy-table-lamp',
    },
  ],
  totalContractValue: 72.0,
});
