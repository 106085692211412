import { Item, ItemDetails, mockItemDetails } from '@interfaces/item.interface';
import { ItemDetailsV2 } from '@interfaces/item_v2.interface';
import { UserCreatedContent } from '@interfaces/user_created_content.interface';
import getConfig from 'next/config';
import { HttpService } from '../http_service';
import {
  FilterCounts,
  FilteredItemResponse,
  mockFilteredItemResponse,
} from './filtered_item_response.interface';
import { SortFieldOptions } from './sort_field_options';

/**
 * This ensures that an object of this type has the same keys as in FilterCounts. See example below.
 */
export type FilteredItemFilters = {
  [Property in keyof FilterCounts]: string[];
};

export type OtherFilters = {
  sort_field: SortFieldOptions | '';
  in_stock: 'true' | '';
};

function getFilteredItems(
  pageParam: number,
  // roomType: number | null, // NOTE - this doesn't have to be a separate param?
  filters: FilteredItemFilters,
  otherFilters: OtherFilters
): Promise<FilteredItemResponse> {
  let url = `/api/v2/public/filter?per_page=12&`;

  // add query params
  const queryParams = [];
  if (pageParam) {
    queryParams.push(`page=${pageParam}`);
  }

  // -------------------
  // "regular" filters
  // -------------------
  // Note - may want to do this programmatically so we don't have to register each new filter? We do this for pills and accordions.
  if (filters.room_types.length > 0) {
    queryParams.push(`room_types=${filters.room_types.join(',')}`);
  }
  if (filters.item_types.length > 0) {
    queryParams.push(`item_types=${filters.item_types.join(',')}`);
  }
  if (filters.color_families.length > 0) {
    queryParams.push(`color_families=${filters.color_families.join(',')}`);
  }
  if (filters.bed_sizes.length > 0) {
    queryParams.push(`bed_sizes=${filters.bed_sizes.join(',')}`);
  }
  if (filters.rug_sizes.length > 0) {
    queryParams.push(`rug_sizes=${filters.rug_sizes.join(',')}`);
  }
  if (filters.materials.length > 0) {
    queryParams.push(`materials=${filters.materials.join(',')}`);
  }

  // -------------------
  // "other" filters
  // -------------------
  // sort field has only one option
  if (otherFilters.sort_field) {
    queryParams.push(`sort_field=${otherFilters.sort_field}`);
  }

  // in stock is a boolean (also has only one option, like above?)
  if (otherFilters.in_stock) {
    queryParams.push(`in_stock=true`);
  }

  // ---
  url += queryParams.join('&');

  // make request
  if (process.env.NEXT_PUBLIC_DEV_MOCK_API_CALLS === 'true') {
    return Promise.resolve(mockFilteredItemResponse({}));
  }

  return HttpService.get(url);
}

function getItem(slug: string): Promise<ItemDetails> {
  // make request
  if (process.env.NEXT_PUBLIC_DEV_MOCK_API_CALLS === 'true') {
    return Promise.resolve(mockItemDetails({}));
  }

  return HttpService.get(`/api/v1/public/items/${slug}`);
}

function getSimpleItemDetailsServerSide(slug: string): Promise<ItemDetailsV2> {
  const { publicRuntimeConfig } = getConfig();
  return HttpService.get(
    `${publicRuntimeConfig.backendBaseUrl}/api/v2/public/items/${slug}`
  );
}

function getNewItems(): Promise<Item[]> {
  return HttpService.get(`/api/v1/public/items/new_items`);
}

function getRelatedItems(slug: string): Promise<Item[]> {
  return HttpService.get(`/api/v1/public/items/${slug}/related_items`);
}

function getUserCreatedContent(slug: string): Promise<UserCreatedContent[]> {
  return HttpService.get(`/api/v1/public/items/${slug}/user_created_contents`);
}

export interface CuratedItemResponse {
  title: string;
  items: Item[];
}

function getCuratedItems(slug: string): Promise<CuratedItemResponse> {
  return HttpService.get(`/api/v1/public/items/${slug}/curated_items`);
}

export const ItemService = {
  getFilteredItems,
  getItem,
  getSimpleItemDetailsServerSide,
  getNewItems,
  getRelatedItems,
  getCuratedItems,
  getUserCreatedContent,
};
