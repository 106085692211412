import { CartService } from '@services/cart_service';
import { useQuery } from 'react-query';

export const CartKey = ['Cart'];

export function useCart() {
  const cartQuery = useQuery(CartKey, CartService.getCart, {
    staleTime: 1000 * 60, // once a minute
  });

  return cartQuery;
}
